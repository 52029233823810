import React from 'react';
import SEO from "../components/seo"

export default class Page3 extends React.Component {

  render() {
    return <div>
      <SEO title="RICHIEDI PREVENTIVO" />
      <div>ciao</div>
      </div>
  }
}
